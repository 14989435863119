_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "\u05d0\u05e0\u05d0 \u05e1\u05e4\u05e7 \u05e2\u05e8\u05da \u05ea\u05d5\u05d0\u05dd \u05e2\u05d1\u05d5\u05e8 {0}",
	"crm.label.field":"\u05e9\u05d3\u05d4",//no i18n
"crm.label.value":"\u05e2\u05e8\u05da",//no i18n
	"sentiment.criteria.wrongcriteria":"\u05e2\u05e8\u05da \u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05df \u05dc\u05d0 \u05d9\u05e2\u05dc\u05d4 \u05e2\u05dc {0}",//no i18n
"crm.mb.field.common.splc":"\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d4\u05e9\u05ea\u05de\u05e9 \u05d1\u05ea\u05d5\u05d5\u05d9\u05dd \u05de\u05d9\u05d5\u05d7\u05d3\u05d9\u05dd. \u05d4\u05db\u05e0\u05e1 \u05e2\u05e8\u05da \u05d7\u05d5\u05e7\u05d9.",//no i18n
	"crm.label.field.plural":"\u05e9\u05d3\u05d5\u05ea",//no i18n
	"crm.label.in.minutes":"{0} (\u05d1\u05d3\u05e7\u05d5\u05ea)",//no i18n
	"crm.security.roles.list":"\u05e8\u05e9\u05d9\u05de\u05ea \u05ea\u05e4\u05e7\u05d9\u05d3\u05d9\u05dd",//no i18n
"crm.security.roles.lookup.info":"\u05d0\u05e0\u05d0 \u05d1\u05d7\u05e8 \u05de\u05d4\u05e8\u05e9\u05d9\u05de\u05d4 \u05ea\u05e4\u05e7\u05d9\u05d3 \u05d0\u05d7\u05d3.",//no i18n
"crm.territory.addterritory":"\u05d4\u05d5\u05e1\u05e3 \u05d8\u05e8\u05d9\u05d8\u05d5\u05e8\u05d9\u05d4",//no i18n
"crm.title.edit.territory":"\u05e2\u05e8\u05d5\u05da \u05d8\u05e8\u05d9\u05d8\u05d5\u05e8\u05d9\u05d4",//no i18n
"crm.territory.title.assign.territories":"\u05d4\u05e7\u05e6\u05d0\u05ea \u05d8\u05e8\u05d9\u05d8\u05d5\u05e8\u05d9\u05d5\u05ea",//no i18n
	"crm.label.context.help":"\u05e2\u05d6\u05e8\u05d4",//no i18n
	"crm.label.from":"\u05de",//no i18n
"crm.label.to":"\u05e2\u05d3",//no i18n
	"workflow.option.webhookFailure.fromDate":"\u05de\u05ea\u05d0\u05e8\u05d9\u05da",//no i18n
"workflow.option.webhookFailure.toDate":"\u05e2\u05d3 \u05ea\u05d0\u05e8\u05d9\u05da",//no i18n
"crm.custom.field.less.than.equalto":"{0} \u05e6\u05e8\u05d9\u05da \u05dc\u05d4\u05d9\u05d5\u05ea \u05e4\u05d7\u05d5\u05ea \u05d0\u05d5 \u05e9\u05d5\u05d5\u05d4 \u05dc{1}.",//no i18n
	"crm.template.listview.search.no.results":"\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 \u05ea\u05d5\u05e6\u05d0\u05d5\u05ea",//No I18n
	"crm.wf.usage.date.criteria.error.msg":'\u05ea\u05d0\u05e8\u05d9\u05da "\u05de" \u05d0\u05d9\u05e0\u05d5 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05d2\u05d3\u05d5\u05dc \u05d9\u05d5\u05ea\u05e8 \u05de\u05ea\u05d0\u05e8\u05d9\u05da "\u05e2\u05d3".',//no i18n
	"crm.label.tag.new":"\u05ea\u05d2\u05d9\u05ea \u05d7\u05d3\u05e9\u05d4",//No I18n
	"crm.label.enter.tag":"\u05d4\u05d6\u05df \u05ea\u05d2\u05d9\u05d5\u05ea",//No I18n
	"crux.comboBox.max.limit":"\u05d0\u05d9\u05e0\u05da \u05d9\u05db\u05d5\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d9\u05d5\u05ea\u05e8 \u05de \u200E {0} \u200E \u200E {1} \u200E.",//NO I18n
	"Administrator":"\u05de\u05e0\u05d4\u05dc",//No I18n
	"Standard":"\u05ea\u05e7\u05e0\u05d9",//No I18n
	"crm.button.add":"\u05d4\u05d5\u05e1\u05e3",//NO I18n
	"crm.label.users":"\u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd", //NO I18n
  "crm.workflow.alert.roles":"\u05ea\u05e4\u05e7\u05d9\u05d3\u05d9\u05dd", //NO I18n
  "crm.security.groups":"\u05e7\u05d1\u05d5\u05e6\u05d5\u05ea", //NO I18n
	"crm.label.available" : "\u05d6\u05de\u05d9\u05df", //NO I18n
	"crm.label.assign.manually" : "\u05d4\u05e7\u05e6\u05d4", //NO I18n
	"crm.globalsearch.option.all": "\u05d4\u05db\u05dc", //NO I18n
	"webform.status.Active":"\u05e4\u05e2\u05d9\u05dc", //NO I18n
	"Inactive":"\u05dc\u05d0 \u05e4\u05e2\u05d9\u05dc", //NO I18n
  "Confirmed":"\u05de\u05d0\u05d5\u05e9\u05e8", //NO I18n
  "crm.user.component.unconfirmed":"\u05dc\u05d0 \u05d0\u05d5\u05e9\u05e8",//no i18n
  "DeletedUser":"\u05e0\u05de\u05d7\u05e7", //NO I18n
  "crm.feed.group.admin":"\u05de\u05e0\u05d4\u05dc \u05de\u05e2\u05e8\u05db\u05ea", //NO I18n
  "crm.ln.lable.current":"\u05e2\u05db\u05e9\u05d5\u05d5\u05d9", //NO I18n
	"crm.label.selected": "\u05e0\u05d1\u05d7\u05e8",//NO I18n
  "crm.auditlog.user": "\u05de\u05e9\u05ea\u05de\u05e9", //NO I18n
  "cob.role": "\u05ea\u05e4\u05e7\u05d9\u05d3", //NO I18n
  "zoho.email": "\u05d3\u05d5\u05d0\"\u05dc", //NO I18n
  "Profile": "\u05e4\u05e8\u05d5\u05e4\u05d9\u05dc", //NO I18n
	"crm.security.group.users.empty": "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 \u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd.", //NO I18n
	"crm.label.picklist.none": "\u05d0\u05d9\u05df", //NO I18n
	"crm.usrpop.non.selected" : "\u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd \u05e9\u05e0\u05d1\u05d7\u05e8\u05d5",//NO I18n
	"crm.label.notSelected" : "\u05dc\u05d0 \u05e0\u05d1\u05d7\u05e8",//NO I18n
	"AM" : "\u05d1\u05d5\u05e7\u05e8",//NO I18n
	"Call" : "\u05e9\u05d9\u05d7\u05ea \u05d8\u05dc\u05e4\u05d5\u05df",//NO I18n
	"crm.phoneNo.Link.Title" : "\u05d4\u05ea\u05e7\u05e9\u05e8 \u05d1\u05d0\u05de\u05e6\u05e2\u05d5\u05ea Skype",//NO I18n
	"crm.no.data.found" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0 \u05de\u05d9\u05d3\u05e2.",//NO I18n
	"crm.zti.label.user": "\u05e9\u05dd \u05de\u05e9\u05ea\u05de\u05e9", //NO I18n
	"crm.label.no.options.found" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 \u05d0\u05e4\u05e9\u05e8\u05d5\u05d9\u05d5\u05ea.",//No I18n
	"crm.globalsearch.search.title" : "\u05d7\u05e4\u05e9",//No I18n
	"None" : "\u05db\u05dc\u05d5\u05dd",//No I18n
	"crm.label.criteria.pattern" : "\u05ea\u05d1\u05e0\u05d9\u05ea \u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd",//No I18n
	"crm.label.edit.criteria.pattern" : "\u05e2\u05e8\u05d9\u05db\u05ea \u05ea\u05d1\u05e0\u05d9\u05ea",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "\u05d4\u05e1\u05d5\u05d2\u05e8\u05d9\u05d9\u05dd \u05d4\u05de\u05e8\u05d5\u05d1\u05e2\u05d9\u05dd \u05e9\u05dc \u05d4\u05ea\u05d1\u05e0\u05d9\u05ea \u05dc\u05d0 \u05de\u05ea\u05d0\u05d9\u05de\u05d9\u05dd.",//No I18n
	"criteria.error.alert.brackets.invalid" : "\u05de\u05d9\u05e8\u05db\u05d0\u05d5\u05ea \u05dc\u05d0 \u05d7\u05d5\u05e7\u05d9\u05d5\u05ea \u05e1\u05d1\u05d9\u05d1 \u05d0\u05d5\u05e4\u05e8\u05d8\u05d5\u05e8(\u05d9\u05dd) \u05ea\u05e0\u05d0\u05d9.",//No I18n
	"crm.criteria.number.notmatch.check" : "\u05d0\u05e0\u05d0 \u05d1\u05d3\u05d5\u05e7 \u05d0\u05ea \u05d4\u05ea\u05d1\u05e0\u05d9\u05ea \u05d1{0}.",//No I18n
	"criteria.error.alert.other.params" : "\u05ea\u05d5\u05db\u05df \u05dc\u05d0 \u05d7\u05d5\u05e7\u05d9 \u05d1\u05ea\u05d1\u05e0\u05d9\u05ea \u05d6\u05d0\u05ea.", //No I18n
	"crm.label.search.for.users": "\u05d7\u05e4\u05e9 \u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "\u05ea\u05d1\u05e0\u05d9\u05ea \u05d4\u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd \u05dc\u05d0 \u05ea\u05d5\u05d0\u05de\u05ea \u05dc\u05ea\u05e0\u05d0\u05d9\u05dd \u05e9\u05d1\u05d7\u05e8\u05ea.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "\u05ea\u05d1\u05e0\u05d9\u05ea \u05d4\u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd \u05dc\u05d0 \u05ea\u05d5\u05d0\u05de\u05ea \u05dc\u05ea\u05e0\u05d0\u05d9\u05dd \u05e9\u05d1\u05d7\u05e8\u05ea.", //No I18n
	"and" : "\u05d5", //No I18n
	"or" : "\u05d0\u05d5", //No I18n
	"crm.label.or" : "\u05d0\u05d5", //No I18n
	"crm.label.and" : "\u05d5", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "\u05d0\u05e0\u05d0 \u05d4\u05db\u05e0\u05e1 \u05ea\u05d5\u05d5\u05d9\u05ea \u05e9\u05d3\u05d4 \u05d7\u05d5\u05e7\u05d9\u05ea \u05d1\u05e9\u05d5\u05e8\u05d4 {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "\u05d0\u05e0\u05d0 \u05e6\u05d9\u05d9\u05df \u05ea\u05e0\u05d0\u05d9 \u05d7\u05d5\u05e7\u05d9 \u05e2\u05d1\u05d5\u05e8 {0}.", //No I18n
	"crm.field.valid.check" : "\u05d0\u05e0\u05d0 \u05d4\u05d6\u05df {0} \u05d7\u05d5\u05e7\u05d9.", //No I18n
	"crm.alert.label.savepattern" : "\u05e9\u05de\u05d5\u05e8 \u05d0\u05ea \u05d4\u05ea\u05d1\u05e0\u05d9\u05ea \u05dc\u05e4\u05e0\u05d9 \u05e9\u05d9\u05e0\u05d5\u05d9 \u05d4\u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d4\u05d5\u05e1\u05d9\u05e3 \u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05e0\u05d9\u05dd \u05e0\u05d5\u05e1\u05e4\u05d9\u05dd.",//No I18n
	"is" : "\u05e0\u05de\u05e6\u05d0",//No I18n
	"isn\'t" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0",//No I18n
	"contains" : "\u05de\u05db\u05d9\u05dc",//No I18n
	"doesn\'t contain" : "\u05dc\u05d0 \u05de\u05db\u05d9\u05dc",//No I18n
	"starts with" : "\u05de\u05ea\u05d7\u05d9\u05dc \u05d1",//No I18n
	"ends with" : "\u05e0\u05d2\u05de\u05e8 \u05d1",//No I18n
	"is empty" : "\u05e8\u05d9\u05e7",//No I18n
	"is not empty" : "\u05d0\u05d9\u05e0\u05d5 \u05e8\u05d9\u05e7",//No I18n
	"is before" : "\u05e0\u05de\u05e6\u05d0 \u05dc\u05e4\u05e0\u05d9",//No I18n
	"is after" : "\u05e0\u05de\u05e6\u05d0 \u05d0\u05d7\u05e8\u05d9",//No I18n
	"between" : "\u05d1\u05d9\u05df",//No I18n
	"not between" : "\u05dc\u05d0 \u05d1\u05d9\u05df",//No I18n
	"Today" : "\u05d4\u05d9\u05d5\u05dd",//No I18n
	"Tommorow" : "\u05de\u05d7\u05e8",//No I18n
	"Tommorow Onwards" : "\u05d4\u05d7\u05dc \u05de\u05de\u05d7\u05e8",//No I18n
	"Yesterday" : "\u05d0\u05ea\u05de\u05d5\u05dc",//No I18n
	"Till Yesterday" : "\u05e2\u05d3 \u05d0\u05ea\u05de\u05d5\u05dc",//No I18n
	"Last Month" : "\u05d7\u05d5\u05d3\u05e9 \u05e7\u05d5\u05d3\u05dd",//No I18n
	"Current Month" : "\u05d7\u05d5\u05d3\u05e9 \u05e0\u05d5\u05db\u05d7\u05d9", //No I18n
	"Next Month" : "\u05d4\u05d7\u05d5\u05d3\u05e9 \u05d4\u05d1\u05d0", //No I18n
	"Last Week" : "\u05e9\u05d1\u05d5\u05e2 \u05e9\u05e2\u05d1\u05e8", //No I18n
	"Current Week" : "\u05e9\u05d1\u05d5\u05e2 \u05e0\u05d5\u05db\u05d7\u05d9", //No I18n
	"Next Week" : "\u05e9\u05d1\u05d5\u05e2 \u05d4\u05d1\u05d0", //No I18n
	"Age in Days" : "\u05d2\u05d9\u05dc \u05d1\u05d9\u05de\u05d9\u05dd", //No I18n
	"Due in Days" : "\u05d9\u05de\u05d9\u05dd \u05dc\u05de\u05d5\u05e2\u05d3", //No I18n
	"Scheduled" : "\u05de\u05ea\u05d5\u05db\u05e0\u05df", //No I18n
	"Attended Dialled" : "\u05d7\u05d9\u05d5\u05d2 \u05de\u05d0\u05d5\u05d9\u05e9", //No I18n
	"Unattended Dialled" : "\u05d7\u05d9\u05d5\u05d2 \u05d1\u05dc\u05ea\u05d9 \u05de\u05d0\u05d5\u05d9\u05e9", //No I18n
	"Overdue" : "\u05d0\u05d7\u05e8 \u05d0\u05ea \u05d4\u05de\u05d5\u05e2\u05d3", //No I18n
	"Cancelled" : "\u05d1\u05d5\u05d8\u05dc", //No I18n
	"Received" : "\u05d4\u05ea\u05e7\u05d1\u05dc", //No I18n
	"Missed" : "\u05e9\u05d9\u05d7\u05d5\u05ea \u05e9\u05dc\u05d0 \u05e0\u05e2\u05e0\u05d5", //No I18n
	"crm.alert.character.not.allowed" : "\u05d0\u05d9\u05e0\u05d5 \u05de\u05d5\u05ea\u05e8 ?{0}", //No I18n
	"crm.condition.in.last" : "\u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd", //No I18n
	"crm.zinvoice.dueIn" : "\u05e6\u05e4\u05d5\u05d9(\u05d4) \u05ea\u05d5\u05da", //No I18n
	"on" : "\u05d1",//No I18n
	"before" : "\u05dc\u05e4\u05e0\u05d9",//No I18n
	"crm.label.general.small.after" : "\u05d0\u05d7\u05e8\u05d9",//No I18n
	"crm.thisweek" : "\u05d4\u05e9\u05d1\u05d5\u05e2",//No I18n
	"crm.label.this.month" : "\u05d4\u05d7\u05d5\u05d3\u05e9",//No I18n
	"crm.thisyear" : "\u05d4\u05e9\u05e0\u05d4",//No I18n
	"crm.source.user.and.system" : "User & System",//No I18n
	"crm.source.user.or.system" : "User Or System",//No I18n
	"crm.label.system2" : "\u05de\u05e2\u05e8\u05db\u05ea",//No I18n
	"crm.source.user.only" : "Only by User",//No I18n
	"crm.source.system.only" : "Only by System",//No I18n
	"crm.condition.till.today" : "\u05e2\u05d3 \u05d4\u05d9\u05d5\u05dd",//No I18n
	"game.month.left" : "1 \u05d7\u05d5\u05d3\u05e9",//No I18n
	"game.months.left" : "{0} \u05d7\u05d5\u05d3\u05e9\u05d9\u05dd",//No I18n
	"crm.condition.last.30.days" : "in the last 30 days",//No I18n
	"crm.condition.last.60.days" : "in the last 60 days",//No I18n
	"crm.condition.last.90.days" : "in the last 90 days",//No I18n
	"crm.label.filter.typehere" : "\u05d4\u05e7\u05dc\u05d3 \u05db\u05d0\u05df", //No I18N
	"crm.filter.is.not" : "\u05d0\u05d9\u05e0\u05d5", //No I18n
	"crm.condition.until.now" : "Until Now",//No I18n
	"crm.filter.email.isblocked" : "\u05d7\u05e1\u05d5\u05dd",//No I18n
	"crm.filter.email.isnotblocked" : "\u05d0\u05d9\u05e0\u05d5 \u05d7\u05e1\u05d5\u05dd",//No I18n
	"crm.label.no.results.match" : "\u05d0\u05d9\u05df \u05ea\u05d5\u05e6\u05d0\u05d5\u05ea \u05ea\u05d5\u05d0\u05de\u05d5\u05ea",//No I18n
	"crm.label.select.user" : "\u05dc\u05d7\u05e5 \u05db\u05d3\u05d9 \u05dc\u05d1\u05d7\u05d5\u05e8 \u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd.", //No I18n
	"current.logged.in.user": "\u05de\u05e9\u05ea\u05de\u05e9 \u05e9\u05d4\u05ea\u05d7\u05d1\u05e8", //NO I18n
	"current.logged.in.user.definition": "\u05d4\u05de\u05e9\u05ea\u05de\u05e9 \u05e9\u05de\u05e4\u05e2\u05d9\u05dc \u05d0\u05ea \u05ea\u05d9\u05e2\u05d5\u05d3 \u05e4\u05e2\u05d5\u05dc\u05d4.", //NO i18n
	"crm.security.group": "\u05e7\u05d1\u05d5\u05e6\u05d4", //NO I18n
	"crm.security.role": "\u05ea\u05e4\u05e7\u05d9\u05d3", //NO I18n
	"Date" : "\u05ea\u05d0\u05e8\u05d9\u05da",//No I18n
	"crm.field.valid.decimal.check2" : "\u05d4\u05e1\u05e4\u05e8\u05d5\u05ea \u05d0\u05d7\u05e8\u05d9 \u05d4\u05e0\u05e7\u05d5\u05d3\u05d4 \u05d4\u05e2\u05e9\u05e8\u05d5\u05e0\u05d9\u05ea \u05d1\u05e9\u05d3\u05d4 <i>{0}</i> \u05e6\u05e8\u05d9\u05db\u05d5\u05ea \u05dc\u05d4\u05d9\u05d5\u05ea \u05e7\u05d8\u05e0\u05d5\u05ea \u05d0\u05d5 \u05e9\u05d5\u05d5\u05d5\u05ea \u05dc-{1}",//No I18n
	"crm.field.empty.check" : "{0} \u05dc\u05d0 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05e8\u05d9\u05e7.",//No I18n
	"crm.label.add.note": "\u05d4\u05d5\u05e1\u05e3 \u05d4\u05e2\u05e8\u05d4", //NO I18n
	"crm.label.simply.by": "\u05e2\u05dc \u05d9\u05d3\u05d9", //NO I18n
	"crm.general.addnote": "\u05d4\u05d5\u05e1\u05e3 \u05d4\u05e2\u05e8\u05d4", //NO I18n
	"crm.general.addtitle": "\u05d4\u05d5\u05e1\u05e3 \u05db\u05d5\u05ea\u05e8\u05ea", //NO I18n
	"crm.label.attach.file": "\u05d4\u05e6\u05de\u05d3 \u05e7\u05d5\u05d1\u05e5", //NO I18N
	"crm.button.cancel": "\u05d1\u05d9\u05d8\u05d5\u05dc", //NO I18N
	"crm.button.save": "\u05e9\u05de\u05d9\u05e8\u05d4", //NO I18N
	"crm.button.mass.delete": "\u05de\u05d7\u05d9\u05e7\u05d4", //NO I18N
	"crm.warning.delete.record": "\u05d4\u05d0\u05dd \u05d0\u05ea\u05d4 \u05d1\u05d8\u05d5\u05d7 \u05e9\u05d1\u05e8\u05e6\u05d5\u05e0\u05da \u05dc\u05d4\u05e2\u05d1\u05d9\u05e8 \u05d0\u05ea \"{0}\" \u05dc\u05e1\u05dc \u05d4\u05de\u05d9\u05d7\u05d6\u05d5\u05e8?", //NO I18N
	"crm.label.yes": "\u05db\u05df", //NO I18N
	"crm.note.view.previous": "\u05d4\u05e6\u05d2 \u05d4\u05e2\u05e8\u05d5\u05ea \u05e7\u05d5\u05d3\u05de\u05d5\u05ea", //NO I18N
  "of": "\u05e9\u05dc", //NO I18N
	"crm.label.notes": "\u05d4\u05e2\u05e8\u05d5\u05ea", //NO I18N
	"crm.note.recent.first": "\u05d0\u05d7\u05e8\u05d5\u05df \u05e8\u05d0\u05e9\u05d5\u05df", //NO I18N
	"crm.note.recent.last": "\u05d0\u05d7\u05e8\u05d5\u05df \u05d1\u05e1\u05d5\u05e3", //NO I18N
	"crm.territory.label.only": "\u05e8\u05e7 {0}", //no i18n
	"crm.select" : "\u05dc\u05d1\u05d7\u05d5\u05e8",//No I18n
	"crm.button.apply.filter" : "\u05d4\u05d7\u05dc\u05ea \u05de\u05e1\u05e0\u05df",//No I18n
	"crm.alert.maximum.text.values.contains" : "\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d4\u05d6\u05d9\u05df \u05d9\u05d5\u05ea\u05e8 \u05de-{0} \u05e2\u05e8\u05db\u05d9\u05dd \u05dc\u05e9\u05d3\u05d4 \u05d6\u05d4.",//No I18n
	"PM" : "\u05d0\u05d7\u05d4\u05f4\u05e6",//No I18n
	"Jan" : "\u05d9\u05e0\u05d5\u05d0",//No I18n
	"Feb" : "\u05e4\u05d1\u05e8",//No I18n
	"Mar" : "\u05de\u05e8\u05e5",//No I18n
	"Apr" : "\u05d0\u05e4\u05e8",//No I18n
	"Jun" : "\u05d9\u05d5\u05e0\u05d9",//No I18n
	"Jul" : "\u05d9\u05d5\u05dc\u05d9",//No I18n
	"Aug" : "\u05d0\u05d5\u05d2",//No I18n
	"Sep" : "\u05e1\u05e4\u05d8\u05de",//No I18n
	"Oct" : "\u05d0\u05d5\u05e7\u05d8",//No I18n
	"Nov" : "\u05e0\u05d5\u05d1\u05de",//No I18n
	"Dec" : "\u05d3\u05e6\u05de",//No I18n
	"crm.mb.newversion.msg4" : "\u05d0\u05d5\u05e7\u05d9\u05d9, \u05d4\u05d1\u05e0\u05ea\u05d9!", //no i18n
	"crm.label.More" :"\u05e2\u05d5\u05d3", //no i18n

	"crm.label.unmapped.stages" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "\u05d4\u05e6\u05d2 \u05e2\u05d5\u05d3",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "\u05d4\u05e6\u05d2 \u05e4\u05d7\u05d5\u05ea",//No I18n

	 	 //filter related keys-start
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.tooltip.convert":"\u05d4\u05e1\u05ea\u05d1\u05e8\u05d5\u05ea",//no i18n
	"Planned":"\u05de\u05ea\u05d5\u05db\u05e0\u05df",//no i18n
	"Invited":"\u05de\u05d5\u05d6\u05de\u05df",//no i18n
	"Sent":"\u05e0\u05e9\u05dc\u05d7",//no i18n
	"Received":"\u05d4\u05ea\u05e7\u05d1\u05dc",//no i18n
	"Opened":"\u05e4\u05ea\u05d5\u05d7",//no i18n
	"Responded":"\u05d4\u05d2\u05d9\u05d1",//no i18n
	"Bounced":"\u05d7\u05d6\u05e8",//no i18n
	"Opted\ Out":"\u05d4\u05e2\u05d3\u05d9\u05e3 \u05dc\u05e6\u05d0\u05ea",//no i18n
	"crm.filter.label.with.open":"\u05e2\u05dd {0} \u05e4\u05ea\u05d5\u05d7",//no i18n
	"crm.filter.label.without.open":"\u05d1\u05dc\u05d9 \u05dc\u05e4\u05ea\u05d5\u05d7 \u05d0\u05ea {0}",//no i18n
	"crm.filter.label.without.any":"\u05dc\u05dc\u05d0 \u05db\u05dc {0}",//no i18n
	"crm.filter.label.with.module":"\u05e2\u05dd {0}",//no i18n
	"crm.filter.label.activity.due":"\u05e2\u05d3 {0}",//no i18n
	"crm.filter.label.activity.done":"{0} \u05d1\u05d5\u05e6\u05e2",//no i18n
	"Notes":"\u05d4\u05e2\u05e8\u05d5\u05ea",//no i18n
	"crm.filter.label.notes.added":"\u05d4\u05e2\u05e8\u05d5\u05ea \u05e9\u05d4\u05ea\u05d5\u05d5\u05e1\u05e4\u05d5",//no i18n
	"crm.label.filter.email.status":"\u05e1\u05d8\u05d0\u05d8\u05d5\u05e1 \u05d4\u05d3\u05d5\u05d0\u05dc \u05d4\u05d0\u05d7\u05e8\u05d5\u05df",//no i18n
	"crm.filter.label.sent":"\u05e0\u05e9\u05dc\u05d7",//no i18n
	"crm.filter.label.not.sent":"\u05dc\u05d0 \u05e0\u05e9\u05dc\u05d7",//no i18n
	"crm.filter.label.opened":"\u05e0\u05e4\u05ea\u05d7",//no i18n
	"crm.filter.label.not.opened":"\u05dc\u05d0 \u05e0\u05e4\u05ea\u05d7",//no i18n
	"crm.filter.label.received":"\u05d4\u05ea\u05e7\u05d1\u05dc",//no i18n
	"crm.filter.label.not.received":"\u05dc\u05d0 \u05d4\u05ea\u05e7\u05d1\u05dc",//no i18n
	"crm.filter.label.bounced":"\u05e0\u05e0\u05d8\u05e9",//no i18n
	"crm.filter.label.opened.not.replied":"\u05e0\u05e4\u05ea\u05d7 \u05d5\u05dc\u05d0 \u05e0\u05e2\u05e0\u05d4", //no i18n
	"crm.filter.label.any":"\u05d0\u05d7\u05d3 \u05de\u05d4\u05e2\u05e8\u05db\u05d9\u05dd \u05e9\u05dc\u05e2\u05d9\u05dc",//no i18n
	"crm.zia.config.potential.amount":"\u05db\u05de\u05d5\u05ea \u200E{0}\u200E",//no i18n
	"Quote\ Stage":"\u05e9\u05dc\u05d1 \u05e9\u05dc {0}",//no i18n
	"crm.module.owner":"\u05d1\u05e2\u05dc\u05d9\u05dd {0}",//no i18n
	"Potential\ Closing\ Date":"\u05ea\u05d0\u05e8\u05d9\u05da \u05e1\u05d2\u05d9\u05e8\u05ea {0}",//no i18n
	"crm.lead.prediction.likely.convert":"\u05e6\u05e4\u05d5\u05d9 \u05dc\u05d4\u05de\u05d9\u05e8",//no i18n
	"crm.lead.prediction.convert.high":"\u05d2\u05d1\u05d5\u05d4",//no i18n
	"crm.lead.prediction.convert.medium":"\u05de\u05d3\u05d9\u05d5\u05dd",//no i18n
	"crm.lead.prediction.convert.low":"\u05e0\u05de\u05d5\u05da",//no i18n
	"crm.intelligence.prediction.likelywin":"\u05e6\u05e4\u05d5\u05d9 \u05dc\u05e0\u05e6\u05d7",//no i18n
	"crm.intelligence.prediction.likelylose":"\u05e6\u05e4\u05d5\u05d9 \u05dc\u05d4\u05e4\u05e1\u05d9\u05d3",//no i18n
	"crm.intelligence.prediction.halfchance":"\u05e1\u05d9\u05db\u05d5\u05d9 \u05e9\u05dc 50:50",//no i18n
	"crm.intelligence.prediction.score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05e6\u05e4\u05d9",//no i18n
	"crm.lead.prediction.recent.score":"\u05d4\u05d3\u05d9\u05e8\u05d5\u05d2 \u05d4\u05d0\u05d7\u05e8\u05d5\u05df \u05e9\u05dc \u05d4\u05e6\u05e4\u05d9",//no i18n
	"crm.intelligence.prediction.lastconv":"3 \u05d4\u05e9\u05d9\u05d7\u05d5\u05ea \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d5\u05ea",//no i18n
	"crm.intelligence.prediction.recordsfocus":"\u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05e2\u05dc\u05d9\u05d4\u05df \u05d9\u05e9 \u05dc\u05d4\u05ea\u05de\u05e7\u05d3",//no i18n
	"crm.intelligence.prediction.slowmoving":"\u05de\u05e2\u05d1\u05e8 \u05d0\u05d9\u05d8\u05d9",//no i18n
	"crm.intelligence.prediction.trend.down":"\u05d1\u05de\u05d2\u05de\u05ea \u05d9\u05e8\u05d9\u05d3\u05d4 \u05dc\u05d0\u05d7\u05e8\u05d5\u05e0\u05d4",//no i18n
	"crm.label.touched.records":"\u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05e9\u05d4\u05e9\u05ea\u05e0\u05d5",//no i18n
	"crm.label.untouched.records":"\u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05e9\u05dc\u05d0 \u05d4\u05e9\u05ea\u05e0\u05d5",//no i18n
	"crm.label.record.action":"\u05e4\u05e2\u05d5\u05dc\u05ea \u05ea\u05d9\u05e2\u05d5\u05d3",//no i18n
	"workflow.rule.view.label.Modified":"\u05d4\u05e9\u05ea\u05e0\u05d4",//no i18n
	"crm.label.not.modified":"\u05dc\u05d0 \u05d4\u05e9\u05ea\u05e0\u05d4",//no i18n
	"crm.label.related.records.action":"\u05e4\u05e2\u05d5\u05dc\u05ea \u05ea\u05d9\u05e2\u05d5\u05d3 \u05de\u05e7\u05d5\u05e9\u05e8\u05ea",//no i18n
	"Done":"\u05d1\u05d5\u05e6\u05e2",//no i18n
	"crm.label.not.done":"\u05dc\u05d0 \u05d1\u05d5\u05e6\u05e2",//no i18n
	"sentiment.model":"\u05d4\u05dc\u05da \u05e8\u05d5\u05d7 \u05d0\u05d9\u05de\u05d9\u05d9\u05dc",//no i18n
	"sentiment.criteria.count":"\u05de\u05e1\u05e4\u05e8",//no i18n
	"sentiment.criteria.percentage":"\u05d0\u05d7\u05d5\u05d6",//no i18n
	"sentiment.criteria.lastmail":"\u05e2\u05d1\u05d5\u05e8 \u05d4\u05d0\u05d9\u05de\u05d9\u05d9\u05dc \u05d4\u05d0\u05d7\u05e8\u05d5\u05df",//no i18n
	"Chats":"\u05e6\u05f3\u05d0\u05d8\u05d9\u05dd",//no i18n
	"Attended":"\u05de\u05d0\u05d5\u05d9\u05e9",//no i18n
	"crm.lead.prediction.popup.text":"\u05db\u05d0\u05e9\u05e8 \u05e1\u05d1\u05d9\u05e8 \u05e9 \u200E{0}\u200E \u05d9\u05e2\u05d1\u05d5\u05e8 \u05d4\u05de\u05e8\u05d4, \u05d4\u05e6\u05e4\u05d9 \u05e9\u05dc \u05e6\u05d1\u05d9\u05e8\u05ea \u05d4\u05e0\u05e7\u05d5\u05d3\u05d5\u05ea \u05d0\u05de\u05d5\u05e8 \u05dc\u05d4\u05d9\u05d5\u05ea \u05d1\u05d9\u05df \u200E{1}\u200E.",//no i18n
	"crm.lead.prediction.popup.final":"\u05e0\u05d0 \u05e9\u05e0\u05d4 \u05d0\u05ea \u05d4\u05de\u05e1\u05e0\u05df \u05d1\u05d4\u05ea\u05d0\u05dd \u05d5\u05e0\u05e1\u05d4 \u05e9\u05d5\u05d1.",//no i18n
	"crm.custom.field.less.than.to1":"\u05d8\u05d5\u05d5\u05d7 '\u05de' \u05dc\u05d0 \u05d9\u05d4\u05d9\u05d4 \u05d2\u05d3\u05d5\u05dc \u05d9\u05d5\u05ea\u05e8 \u05de\u05d8\u05d5\u05d5\u05d7 '\u05dc'.",//no i18n
	"crm.custom.field.less.than.to":"\u05d4\u05d8\u05d5\u05d5\u05d7 \u05e9\u05dc <i>\u05de</i> \u05d0\u05d9\u05e0\u05d5 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05d2\u05d3\u05d5\u05dc \u05d9\u05d5\u05ea\u05e8 \u05de\u05d4\u05d8\u05d5\u05d5\u05d7 \u05e9\u05dc  <i>\u05dc</i> .", //no i18n
	"Last\ Activity\ Date":"\u05ea\u05d0\u05e8\u05d9\u05da \u05e4\u05e2\u05d9\u05dc\u05d5\u05ea \u05d0\u05d7\u05e8\u05d5\u05e0\u05d4",//no i18n
	"crm.label.vendor.name":"\u05e9\u05dd {0}",//no i18n
	"hours":"\u05e9\u05e2\u05d5\u05ea",//no i18n
	"days":"\u05d9\u05de\u05d9\u05dd",//no i18n
	"weeks":"\u05e9\u05d1\u05d5\u05e2\u05d5\u05ea",//no i18n
	"months":"\u05d7\u05d3\u05e9\u05d9\u05dd",//no i18n
	"years":"\u05e9\u05e0\u05d9\u05dd",//no i18n
	"crm.label.general.small.after":"\u05d0\u05d7\u05e8\u05d9",//no i18n
	"Last\ Week":"\u05e9\u05d1\u05d5\u05e2 \u05e9\u05e2\u05d1\u05e8",//no i18n
	"Last\ Month":"\u05d7\u05d5\u05d3\u05e9 \u05e7\u05d5\u05d3\u05dd",//no i18n
	"crm.module.name":"\u05e9\u05dd {0}",//no i18n
	"Campaign":"\u05e7\u05de\u05e4\u05d9\u05d9\u05df",//no i18n
	"Tasks":"\u05de\u05e9\u05d9\u05de\u05d5\u05ea",//no i18n
	"Calls":"\u05e9\u05d9\u05d7\u05d5\u05ea \u05d8\u05dc\u05e4\u05d5\u05df",//no i18n
	"Events":"\u05d0\u05d9\u05e8\u05d5\u05e2\u05d9\u05dd",//no i18n
	"sentiment.criteria.wrongcriteria":"\u05e2\u05e8\u05da \u05e7\u05e8\u05d9\u05d8\u05e8\u05d9\u05d5\u05df \u05dc\u05d0 \u05d9\u05e2\u05dc\u05d4 \u05e2\u05dc \u200E{0}\u200E",//no i18n
	"crm.chosen.minimum.input.text":"\u05e0\u05d0 \u05d4\u05d6\u05df \u200E {0} \u200E  \u05d0\u05d5 \u05d9\u05d5\u05ea\u05e8 \u05ea\u05d5\u05d5\u05d9\u05dd",//no i18n
	"crm.intelligence.prediction.trendup":"\u05e0\u05d5\u05d8\u05d4 \u05de\u05d8\u05d4",//no i18n
	"crm.intelligence.prediction.trenddown":"\u05d1\u05de\u05d2\u05de\u05ea \u05d9\u05e8\u05d9\u05d3\u05d4",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"\u05d4\u05d5\u05e9\u05dc\u05dd" ,//no i18n
	"crm.label.success":"\u05d4\u05e6\u05dc\u05d7\u05d4" ,//no i18n
	"crm.label.Failure":"\u05db\u05d9\u05e9\u05dc\u05d5\u05df" ,//no i18n
	"Both":"\u05e9\u05e0\u05d9\u05d4\u05dd" ,//no i18n
	"crm.condition.cannot.empty":"\u05ea\u05e0\u05d0\u05d9 \u05d0\u05d9\u05e0\u05d5 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05e8\u05d9\u05e7",//no i18n
	"crm.predictions.feature.label":"\u05ea\u05d7\u05d6\u05d9\u05ea",//no i18n
	"crm.condition.last.30.days":"\u05d1 30 \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd",//no i18n
	"crm.condition.last.60.days":"\u05d1 60 \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd",//no i18n
	"crm.condition.last.90.days":"\u05d1 90 \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d0\u05d7\u05e8\u05d5\u05e0\u05d9\u05dd",//no i18n
	"crm.sentiment.Positive":"\u05d7\u05d9\u05d5\u05d1\u05d9",//no i18n
	"crm.sentiment.Negative":"\u05e9\u05dc\u05d9\u05dc\u05d9",//no i18n
	"sentiment.positiveandnegative":"\u05d7\u05d9\u05d5\u05d1\u05d9 \u05d5\u05e9\u05dc\u05d9\u05dc\u05d9",//no i18n
	"sentiment.positiveornegative":"\u05d7\u05d9\u05d5\u05d1\u05d9 \u05d0\u05d5 \u05e9\u05dc\u05d9\u05dc\u05d9",//no i18n
	"sentiment.positiveonly":"\u05d7\u05d9\u05d5\u05d1\u05d9 \u05d1\u05dc\u05d1\u05d3",//no i18n
	"sentiment.negativeonly":"\u05e9\u05dc\u05d9\u05dc\u05d9 \u05d1\u05dc\u05d1\u05d3",//no i18n
	"crm.sentiment.Neutral":"\u05e0\u05d9\u05d9\u05d8\u05e8\u05dc\u05d9",//no i18n
	"crm.filters.select.campaign.type":"\u05d1\u05d7\u05e8 \u05e1\u05d5\u05d2 \u200E {0} \u200E",//no i18n
	"crm.filters.select.campaign.status":"\u05d1\u05d7\u05e8 \u05e1\u05d8\u05d0\u05d8\u05d5\u05e1 \u200E {0} \u200E",//no i18n
	"campaign.Member" : "\u05d7\u05d1\u05e8",//no i18n
	"Service":"\u05e9\u05d9\u05e8\u05d5\u05ea",//no i18n
	"Activities":"\u05e4\u05e2\u05d9\u05dc\u05d5\u05d9\u05d5\u05ea",//no i18n
	"crm.livedesk.pot.nextdays":"{0} \u05d4\u05d9\u05de\u05d9\u05dd \u05d4\u05d1\u05d0\u05d9\u05dd",//no i18n
	"Today\ +\ Overdue":"\u05d4\u05d9\u05d5\u05dd + \u05d6\u05de\u05df \u05e4\u05d9\u05d2\u05d5\u05e8",//no i18n
	"crm.source.user.and.system":"\u05de\u05e9\u05ea\u05de\u05e9 \u05d5 System",//no i18n
	"crm.source.user.or.system":"\u05de\u05e9\u05ea\u05de\u05e9 \u05d0\u05d5 System",//no i18n
	"User":"\u05de\u05e9\u05ea\u05de\u05e9",//no i18n
	"crm.source.user.only":"\u05e8\u05e7 \u05d1\u05d0\u05de\u05e6\u05e2\u05d5\u05ea \u05de\u05e9\u05ea\u05de\u05e9",//no i18n
	"crm.source.system.only":"\u05e8\u05e7 \u05d1\u05d0\u05de\u05e6\u05e2\u05d5\u05ea  \u05d4 System",//no i18n
	"Scheduled":"\u05de\u05ea\u05d5\u05db\u05e0\u05df",//no i18n
	"Attended\ Dialled":"\u05d7\u05d9\u05d5\u05d2 \u05de\u05d0\u05d5\u05d9\u05e9",//no i18n
	"Unattended\ Dialled":"\u05d7\u05d9\u05d5\u05d2 \u05d1\u05dc\u05ea\u05d9 \u05de\u05d0\u05d5\u05d9\u05e9",//no i18n
	"Cancelled":"\u05d1\u05d5\u05d8\u05dc",//no i18n
	"crm.filter.email.isblocked":"\u05d7\u05e1\u05d5\u05dd",//no i18n
	"crm.filter.email.isnotblocked":"\u05d0\u05d9\u05e0\u05d5 \u05d7\u05e1\u05d5\u05dd",//no i18n
	"condition.till.now":"\u05e2\u05d3 \u05e2\u05db\u05e9\u05d9\u05d5",//no i18n
	"crm.recurring.no.months":"{0} \u05d7\u05d5\u05d3\u05e9\u05d9\u05dd",//no i18n
	"crm.lead.prediction.tooltip":"\u05e6\u05e4\u05d5\u05d9 \u05dc\u05d4\u05de\u05d9\u05e8 - \u05d8\u05d5\u05d5\u05d7 \u05d3\u05d9\u05e8\u05d5\u05d2",//no i18n
	"crm.website.activity":"\u05e4\u05e2\u05d9\u05dc\u05d5\u05ea \u05d0\u05ea\u05e8 \u05d0\u05d9\u05e0\u05d8\u05e8\u05e0\u05d8",//no i18n
	"crm.label.By":"\u05e2\u05dc \u05d9\u05d3\u05d9",//no i18n
	"crm.chosen.searching.text":"\u05de\u05d7\u05e4\u05e9...",//no i18n
	"crm.label.memberstatus.is":"\u05d5\u05e1\u05d8\u05d0\u05d8\u05d5\u05e1 \u05d4\u05d7\u05d1\u05e8 \u05d4\u05d5\u05d0",//no i18n
	"crm.events.duration":"\u05de\u05e9\u05da",//no i18n
	"crm.title.clear.name":"\u05e0\u05e7\u05d4",//no i18n
	"crm.label.status.is":"\u05d5\u05d4\u05e1\u05d8\u05d8\u05d5\u05e1 \u05d4\u05d5\u05d0",//no i18n
	"zia.last3.help":'\u05e9\u05d9\u05d7\u05d5\u05ea \u05db\u05d5\u05dc\u05dc\u05d5\u05ea \u05e9\u05d9\u05d7\u05d5\u05ea \u05d8\u05dc\u05e4\u05d5\u05df, \u05de\u05e9\u05d9\u05de\u05d5\u05ea, \u200E {0} \u200E, \u05d3\u05d5\u05d0"\u05dc \u05e9\u05d4\u05ea\u05e7\u05d1\u05dc, \u05d4\u05e2\u05e8\u05d5\u05ea, \u05d1\u05d9\u05e7\u05d5\u05e8\u05d9\u05dd, \u05d4\u05e2\u05e8\u05d5\u05ea \u05d7\u05d1\u05e8\u05ea\u05d9\u05d5\u05ea, \u05d1\u05e7\u05e9\u05d5\u05ea \u05ea\u05de\u05d9\u05db\u05d4 \u05de Desk.',//no i18n
	"crm.label.tag.related.to":"\u05e7\u05e9\u05d5\u05e8 \u05dc",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "\u05d9\u05d9\u05e6\u05d5\u05e8 {0} \u05d7\u05d3\u05e9",//No I18n
	"crm.krp.no.records.found" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 {0}",//No I18n
	"crm.module.new" : "\u05d4\u05d5\u05e1\u05e4\u05d4 \u05e9\u05dc {0}",//No I18n
	"crm.label.view" : "\u05e6\u05e4\u05d4",//No I18n
	"crm.nsocial.customers" : "\u05dc\u05e7\u05d5\u05d7\u05d5\u05ea",//No I18n
	"crm.nsocial.open.potential" : "\u05e4\u05ea\u05d7 \u05d0\u05ea {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "\u05d0\u05d7\u05e8\u05d9\u05dd",//No i18n
	"crm.field.length.check" : "\u05e2\u05e8\u05da \u05d4{0} \u05d7\u05d5\u05e8\u05d2 \u05de\u05d4\u05d0\u05d5\u05e8\u05da \u05d4\u05de\u05e8\u05d1\u05d9.", //No I18n
	"crm.lower.now":"\u05e2\u05db\u05e9\u05d9\u05d5",//no i18n
	"crm.time.min.ago":"\u05dc\u05e4\u05e0\u05d9 {0} \u05d3\u05e7\u05d5\u05ea",//no i18n
	"crm.time.mins.ago":"\u05dc\u05e4\u05e0\u05d9 {0} \u05d3\u05e7\u05d5\u05ea",//no i18n
	"crm.time.hr.ago":"\u05dc\u05e4\u05e0\u05d9 {0} \u05e9\u05e2\u05d4",//no i18n
	"crm.time.hrs.ago":"\u05dc\u05e4\u05e0\u05d9 {0} \u05e9\u05e2\u05d5\u05ea", //no i18n
	"AllUsers": "\u05db\u05dc \u05d4\u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd", //no i18n
	"crm.label.search":"\u05d7\u05d9\u05e4\u05d5\u05e9",//no i18n
	"crm.api.filterby":"\u05e1\u05e0\u05df \u05dc\u05e4\u05d9",//no i18n
	"crm.customview.nofields.found":"--\u05d0\u05d9\u05df \u05e9\u05d3\u05d5\u05ea \u05ea\u05d5\u05d0\u05de\u05d9\u05dd--",//no i18n
	"crm.setup.system.ziarecommendation":"\u05d4\u05de\u05dc\u05e6\u05d5\u05ea Zia",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"\u05e0\u05d0 \u05d1\u05d7\u05e8 \u05e2\u05e8\u05da.",//no i18n
	"crm.cal.custom":"\u05de\u05d5\u05ea\u05d0\u05dd \u05d0\u05d9\u05e9\u05d9\u05ea",//no i18n
	"crm.mb.field.common.empt":"\u05d4\u05e2\u05e8\u05da \u05dc\u05d0 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05e8\u05d9\u05e7.",//no i18n
	"crm.chosen.error.loading.text":"\u05d0\u05d5\u05e4\u05e1, \u05dc\u05d0 \u05d4\u05e6\u05dc\u05d7\u05e0\u05d5 \u05dc\u05d8\u05e2\u05d5\u05df \u05d0\u05ea \u05d4\u05ea\u05d5\u05e6\u05d0\u05d5\u05ea.",//no i18n
	"crm.mxnlookup.select" : "\u05d4\u05e7\u05e6\u05d4 \u200E {0} \u200E",//No I18n
	"crm.lookup.chooserecord":"\u05d1\u05d7\u05d9\u05e8\u05ea {0}",//no i18n
	"crm.record.selected":"{0} \u05e0\u05d1\u05d7\u05e8",//no i18n
	"crm.module.empty.message" : "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5 {0}",//No I18n
	"crm.mxnlookup.selected" : "\u200E {0} \u200E \u05e9\u05d4\u05d5\u05e7\u05e6\u05d5",//No I18n
	"crm.security.error" : "\u05d0\u05d9\u05df \u05dc\u05da \u05de\u05e1\u05e4\u05d9\u05e7 \u05d4\u05e8\u05e9\u05d0\u05d5\u05ea \u05dc\u05d1\u05d9\u05e6\u05d5\u05e2 \u05e4\u05e2\u05d5\u05dc\u05d4 \u05d6\u05d5. \u05e4\u05e0\u05d4 \u05d0\u05dc \u05de\u05e0\u05d4\u05dc \u05d4\u05de\u05e2\u05e8\u05db\u05ea \u05e9\u05dc\u05da.", //no i18n
	"crm.label.creator.noPermission" : "\u05d0\u05d9\u05df \u05dc\u05da \u05d4\u05e8\u05e9\u05d0\u05d4 \u05dc\u05d2\u05e9\u05ea", //no i18n
	"crm.reviewprocess.smart.filter" : "\u05e1\u05d8\u05d0\u05d8\u05d5\u05e1 \u05e8\u05e9\u05d5\u05de\u05ea \u05ea\u05d4\u05dc\u05d9\u05da \u05e1\u05e7\u05d9\u05e8\u05d4", //no i18n
	"crm.segmentation.segment.score" : "\u05e0\u05d9\u05e7\u05d5\u05d3 \u05de\u05d3\u05d5\u05e8" ,//No I18n
	"crm.segmentation.frequency" : "\u05ea\u05d3\u05d9\u05e8\u05d5\u05ea", //No I18n
	"crm.segmentation.recency" : "\u05d4\u05d9\u05e9\u05e0\u05d5\u05ea", //No I18n
	"crm.segmentation.monetary" : "\u05db\u05e1\u05e4\u05d9", //No I18n
	"crm.smartfilter.related.module.msg" : "\u05d0\u05d9\u05e0\u05da \u05d9\u05db\u05d5\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d9\u05d5\u05ea\u05e8 \u05de\u05e9\u05dc\u05d5\u05e9\u05d4 \u05de\u05d5\u05d3\u05d5\u05dc\u05d9\u05dd \u05de\u05e7\u05d5\u05e9\u05e8\u05d9\u05dd." , //no i18n
	"crm.smartfilter.related.module.msg1"  : "(\u05dc\u05d3\u05d5\u05d2' : \u05d3\u05d5\u05d0\u05dc, \u05e4\u05e2\u05d9\u05dc\u05d5\u05d9\u05d5\u05ea, \u05d4\u05e2\u05e8\u05d5\u05ea)", //no i18n
	"crm.smartfilter.related.module.msg2" : "\u05de\u05e9\u05da \u05d4\u05d6\u05de\u05df \u05d0\u05d9\u05e0\u05d5 \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05d9\u05d5\u05ea \u05e8\u05d9\u05e7", //no i18n
	"crm.label.timeZone": "\u05d0\u05d6\u05d5\u05e8 \u05d6\u05de\u05df", //NO I18n
	"crm.label.insufficient.privileges": "\u05d4\u05e8\u05e9\u05d0\u05d5\u05ea \u05d1\u05dc\u05ea\u05d9 \u05de\u05e1\u05e4\u05d9\u05e7\u05d5\u05ea \u05dc\u05d1\u05d9\u05e6\u05d5\u05e2 \u05d4\u05e4\u05e2\u05d5\u05dc\u05d4. \u05e6\u05d5\u05e8 \u05e7\u05e9\u05e8 \u05e2\u05dd \u05d4 Admin \u05e9\u05dc\u05da.", //NO I18n
	"crm.createfield.calcinfo.new" : "\u05e9\u05d3\u05d4 \u05d6\u05d4 \u05e4\u05d5\u05e2\u05dc \u05db\u05de\u05d7\u05e9\u05d1\u05d5\u05df \u05e2\u05d1\u05d5\u05e8 \u05db\u05dc \u05d1\u05d9\u05d8\u05d5\u05d9 \u05e9\u05d9\u05d5\u05d6\u05df \u05d1\u05d5. </br> <b>\u05dc\u05d3\u05d5\u05d2':  20+20</b> \u05d9\u05e0\u05d9\u05d1  <b>40</b> \u05d1\u05d0\u05d5\u05e4\u05df \u05d0\u05d5\u05d8\u05d5\u05de\u05d8\u05d9",//No i18n
	"crm.lable.read.only" : "\u05e9\u05d3\u05d4 \u05e7\u05e8\u05d9\u05d0\u05d4 \u05d1\u05dc\u05d1\u05d3",//No i18n
	"crm.column.sort.asc" : "\u05e1\u05d3\u05e8 \u05e2\u05d5\u05dc\u05d4",//No I18n
	"crm.column.sort.desc" : "\u05e1\u05d3\u05e8 \u05d9\u05d5\u05e8\u05d3",//No i18n
	"crm.column.unsort" : "\u05dc\u05dc\u05d0 \u05de\u05d9\u05d5\u05df",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\u05e9\u05ea\u05e3 \u05e2\u05dd \u05dc\u05e7\u05d5\u05d7", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "\u05e8\u05e7 {0} \u05ea\u05d5\u05d5\u05d9\u05dd \u05de\u05d5\u05ea\u05e8\u05d9\u05dd \u05e2\u05d1\u05d5\u05e8 {1}", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\u05de\u05e9\u05d5\u05ea\u05e3 \u05e2\u05dd \u05dc\u05e7\u05d5\u05d7", //NO I18N
	"crm.button.ok" : "\u05d0\u05d9\u05e9\u05d5\u05e8", //NO I18N
	"crm.role.already.selected" : "\u05ea\u05e4\u05e7\u05d9\u05d3 \u05d6\u05d4 \u05db\u05d1\u05e8 \u05e0\u05d1\u05d7\u05e8", //no i18n
	"crm.user.deleted": "\u05d4\u05de\u05e9\u05ea\u05de\u05e9 \u05e0\u05de\u05d7\u05e7",  //NO I18N
	"crm.account.closed": "\u05d7\u05e9\u05d1\u05d5\u05df \u05d6\u05d4 \u05e0\u05e1\u05d2\u05e8",  //NO I18N
	"crm.start.chat": "\u05d4\u05ea\u05d7\u05dc \u05e6'\u05d0\u05d8",  //NO I18N
	"crm.start.call": "\u05d4\u05ea\u05d7\u05dc \u05e9\u05d9\u05d7\u05d4",  //NO I18N
	"crm.recipient.invalid.email" : "\u05e0\u05de\u05e6\u05d0\u05d5 \u05d3\u05d5\u05d0'\u05dc\u05d9\u05dd \u05dc\u05d0 \u05d7\u05d5\u05e7\u05d9\u05d9\u05dd.", //NO I18N
	"crm.recipient.add.recipient" : "\u05d4\u05d5\u05e1\u05e3 \u05e0\u05de\u05e2\u05df \u05e0\u05d5\u05e1\u05e3", //NO I18N
	"crm.start.video.call": "\u05d4\u05ea\u05d7\u05dc \u05e9\u05d9\u05d7\u05ea \u05d5\u05d9\u05d3\u05d0\u05d5",  //NO I18N //ignorei18n_start

	"Score":"\u05d3\u05d9\u05e8\u05d5\u05d2",
	"Positive Score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05d7\u05d9\u05d5\u05d1\u05d9",
	"Positive Touch Point Score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05d7\u05d9\u05d5\u05d1\u05d9 \u05e9\u05dc \u05e0\u05e7\u05d5\u05d3\u05ea \u05d4\u05de\u05d2\u05e2",
	"Negative Score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05e9\u05dc\u05d9\u05dc\u05d9",
	"Negative Touch Point Score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05e9\u05dc\u05d9\u05dc\u05d9 \u05e9\u05dc \u05e0\u05e7\u05d5\u05d3\u05ea \u05d4\u05de\u05d2\u05e2",
	"Touch Point Score":"\u05d3\u05d9\u05e8\u05d5\u05d2 \u05e9\u05dc \u05e0\u05e7\u05d5\u05d3\u05ea \u05d4\u05de\u05d2\u05e2",
	"crm.label.scoring.rules":"\u05db\u05dc\u05dc\u05d9 \u05d3\u05d9\u05e8\u05d5\u05d2",
	"crm.label.type.minutes": "\u05d4\u05e7\u05dc\u05d3 \u05db\u05d0\u05df \u05d1\u05d3\u05e7\u05d5\u05ea", //NO I18N

	"is\ OPEN":"\u05e4\u05ea\u05d5\u05d7\u05d4",//no i18n
	"is\ WON":"\u05d6\u05db\u05d5\u05d4 \u05d1\u05d4",//no i18n
	"is\ LOST":"\u05d4\u05d5\u05e4\u05e1\u05d3\u05d4",//no i18n
	"crm.potential.all.open":"\u05db\u05dc \u05d4\u05e9\u05dc\u05d1\u05d9\u05dd \u05d4\u05e4\u05ea\u05d5\u05d7\u05d9\u05dd",//no i18n
	"crm.potential.all.won":"\u05db\u05dc \u05d4\u05e9\u05dc\u05d1\u05d9\u05dd \u05e9\u05e0\u05d5\u05e6\u05d7\u05d5 \u05e9\u05e0\u05e1\u05d2\u05e8\u05d5",//no i18n
	"crm.potential.all.lost":"\u05db\u05dc \u05d4\u05e9\u05dc\u05d1\u05d9\u05dd \u05e9\u05d4\u05d5\u05e4\u05e1\u05d3\u05d5 \u05e9\u05e0\u05e1\u05d2\u05e8\u05d5",//no i18n

	"crm.campaign.member.status" : "\u05e1\u05d8\u05d8\u05d5\u05e1 \u05d7\u05d1\u05e8",//no i18n
	"crm.dashboard.select.type" : "\u05d1\u05d7\u05e8 {0}",//no i18n
	"crm.campaign.service.status":"\u05e1\u05d8\u05d0\u05d8\u05d5\u05e1 \u05e9\u05d9\u05e8\u05d5\u05ea",//no i18n

	"crm.label.addColumn":"\u05d4\u05d5\u05e1\u05e3 \u05e2\u05de\u05d5\u05d3\u05d4",//no i18n
	"crm.button.clear.filter":"\u05e1\u05d2\u05d5\u05e8 \u05de\u05e1\u05e0\u05df",//no i18n
	"crm.button.show.filter":"\u05d4\u05e6\u05d2 \u05de\u05e1\u05e0\u05df",//no i18n
	"crm.las.error.user.maxlimit":"\u05d4\u05de\u05e1\u05e4\u05e8 \u05d4\u05de\u05e8\u05d1\u05d9 \u05e9\u05dc \u05de\u05e9\u05ea\u05de\u05e9\u05d9\u05dd \u05e9\u05ea\u05d5\u05db\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d4\u05d5\u05d0 20.",//no i18n
	"crm.las.error.picklist.maxlimit":"\u05d4\u05de\u05e1\u05e4\u05e8 \u05d4\u05de\u05e8\u05d1\u05d9 \u05e9\u05dc \u05d0\u05e4\u05e9\u05e8\u05d5\u05d9\u05d5\u05ea \u05e9\u05ea\u05d5\u05db\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d4\u05d5\u05d0 20.",//no i18n

	"crm.fileuploader.message.responseerror": "\u05d4\u05d4\u05e2\u05dc\u05d0\u05d4 \u05e0\u05db\u05e9\u05dc\u05d4", //NO I18N
	"crm.storage.create.error":"\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d9\u05e6\u05d5\u05e8 \u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05d7\u05d3\u05e9\u05d5\u05ea \u05de\u05e9\u05d5\u05dd \u05e9\u05d4\u05d2\u05e2\u05ea \u05d0\u05dc \u05d2\u05d1\u05d5\u05dc \u05d0\u05d7\u05e1\u05d5\u05df \u05d4\u05e0\u05ea\u05d5\u05e0\u05d9\u05dd \u05d4\u05de\u05e8\u05d1\u05d9 \u05e9\u05dc\u05da.",//no i18n
	"crm.storage.create.error.client":"\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05dc\u05d9\u05e6\u05d5\u05e8 \u05e8\u05e9\u05d5\u05de\u05d5\u05ea \u05d7\u05d3\u05e9\u05d5\u05ea \u05de\u05e9\u05d5\u05dd \u05e9\u05d4\u05de\u05e0\u05d4\u05dc \u05e9\u05dc\u05da \u05d4\u05d2\u05d9\u05e2 \u05dc\u05d2\u05d1\u05d5\u05dc \u05d4\u05d0\u05d7\u05e1\u05d5\u05df \u05d4\u05de\u05e8\u05d1\u05d9 \u05e9\u05dc\u05d5. \u05e6\u05d5\u05e8 \u05e7\u05e9\u05e8 \u05e2\u05dd {0} \u05db\u05d3\u05d9 \u05dc\u05e4\u05ea\u05d5\u05e8 \u05d1\u05e2\u05d9\u05d4 \u05d6\u05d5.",//no i18n
	"crm.storage.avail.info":"({0} \u05e0\u05d5\u05ea\u05e8 \u05de\u05ea\u05d5\u05da {1})",//no i18n
	"crm.storage.error.key.manage":"\u05e0\u05d4\u05dc \u05d0\u05ea \u05db\u05dc \u05d0\u05d7\u05e1\u05d5\u05df \u05d4\u05e0\u05ea\u05d5\u05e0\u05d9\u05dd \u05e9\u05dc\u05da",//no i18n
	"Records":"\u05e8\u05e9\u05d5\u05de\u05d5\u05ea",//no i18n
	"crm.workflow.alert.additional.recipients" : "\u05de\u05e7\u05d1\u05dc\u05d9\u05dd \u05e0\u05d5\u05e1\u05e4\u05d9\u05dd", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "\u05d4\u05e9\u05ea\u05de\u05e9 \u05d1\u05e4\u05e1\u05d9\u05e7\u05d9\u05dd \u05dc\u05d4\u05e4\u05e8\u05d3\u05d4 \u05d1\u05d9\u05df \u05db\u05ea\u05d5\u05d1\u05d5\u05ea \u05d3\u05d5\u05d0\u05dc \u05e0\u05d5\u05e1\u05e4\u05d5\u05ea.", //NO I18N
	"crm.related.contact.account" : "{0} \u05de\u05e7\u05d5\u05e9\u05e8 \u05dc {1}",//No I18n
	"crm.allcontact.show" : "\u05db\u05dc \u05d4{0}",//No I18n
	"crm.button.mass.show" : "\u05d4\u05e6\u05d2",//No I18n
	"crm.msg.custom.view.not.replied" : "Not Replied Messages", //NO I18N
	"crm.msg.custom.view.replied" : "Replied Messages",//NO I18N
	"crm.workflow.select.recipients" : "\u05d1\u05d7\u05e8 \u05de\u05e7\u05d1\u05dc\u05d9\u05dd", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\u05d0\u05e0\u05d0 \u05d1\u05d7\u05e8 \u05dc\u05e4\u05d7\u05d5\u05ea \u05e4\u05e8\u05d5\u05e4\u05d9\u05dc \u05d0\u05d7\u05d3.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u05de\u05e6\u05d8\u05e2\u05e8\u05d9\u05dd, \u05d0\u05da \u05d0\u05d9\u05e0\u05da \u05d9\u05db\u05d5\u05dc \u05dc\u05d4\u05e1\u05d9\u05e8 \u05e4\u05e8\u05d5\u05e4\u05d9\u05dc \u05d3\u05d9\u05e4\u05d5\u05dc\u05d8\u05d9\u05d1\u05d9.",//NO I18N
	"crm.inv.label.add.emails" : "\u05d4\u05d5\u05e1\u05e3 \u05db\u05ea\u05d5\u05d1\u05d5\u05ea \u05d3\u05d5\u05d0\u05dc", //NO I18N
	"crm.prediction.analytics.filter.year":"\u05e9\u05e0\u05d4 \u05e9\u05e2\u05d1\u05e8\u05d4",//no i18n
	"Previous\ FY":"FY \u05e7\u05d5\u05d3\u05dd",//no i18n
	"Current\ FY":"FY \u05e0\u05d5\u05db\u05d7\u05d9",//no i18n
	"Next\ FY":"FY \u05d4\u05d1\u05d0",//no i18n
	"Current\ FQ":"FQ \u05e0\u05d5\u05db\u05d7\u05d9",//no i18n
	"Next\ FQ":"FQ \u05d4\u05d1\u05d0",//no i18n
	"Previous\ FQ":"FQ \u05e7\u05d5\u05d3\u05dd",//no i18n
	"crm.picklist.sample.text":"\u05d8\u05e7\u05e1\u05d8 \u05dc\u05d3\u05d5\u05d2\u05de\u05d4",//no i18n
	"crm.more.colors":"\u05e6\u05d1\u05e2\u05d9\u05dd \u05e0\u05d5\u05e1\u05e4\u05d9\u05dd",//no i18n
	"crm.button.back.alone":"\u05d7\u05d6\u05e8\u05d4",//no i18n
	"crm.field.label.email":"\u05d3\u05d5\u05d0\u05f4\u05dc",//no i18n
"crm.zia.nba.feature.label":"\u05d4\u05e4\u05e2\u05d5\u05dc\u05d4 \u05d4\u05de\u05d9\u05d8\u05d1\u05d9\u05ea \u05d4\u05d1\u05d0\u05d4",//no i18n
"Meeting":"\u05e4\u05d2\u05d9\u05e9\u05d4",//no i18n
"Tomorrow":"\u05de\u05d7\u05e8",//no i18n
"crm.gdpr.notavailable.field":"\u05dc\u05d0 \u05d6\u05de\u05d9\u05df",//no i18n
	"crm.setup.system.ziasimilarity":"\u05d4\u05de\u05dc\u05e6\u05ea \u05d3\u05de\u05d9\u05d5\u05df",//no i18n
	"crm.gdpr.notavailable.field":"\u05dc\u05d0 \u05d6\u05de\u05d9\u05df",//no i18n
	"crm.filter.label.all.products":"\u05db\u05dc \u05d4{0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics" ,// NO I18N
	"crux.custom.field.greater.than.equalto":"{0} צריך להיות גדול או שווה ל{1}.",
	"crux.users.selected.plural" : "נבחרו {0} משתמשים.",
	"crux.user.selected.singular" :"נבחר משתמש {0}.",
	"crux.criteria.empty.secondaryfield.module" : "לא נמצא שדה {0} תואם במודול {1}",
	"crux.criteria.empty.secondaryfield" : "אין שדה {0} אחר הזמין להשוואה, אנא הזן ערך להשוואה.", 
	"crux.logged.in.role.definition" : "תפקיד המשתמש שמפעיל את פעולות הרישום",
	"zia.similarity.smartfilter.score":"\u05e6\u05d9\u05d5\u05df \u05d3\u05d5\u05de\u05d4",//no i18n
	"zia.similarity.smartfilter.records":"{0} \u05d3\u05d5\u05de\u05d4 \u05dc-",//no i18n
	"zia.similarity.smartfilter.records.search":"\u05d4\u05e6\u05d2 {0} \u05d3\u05d5\u05de\u05d9\u05dd \u05dc-",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u05dc\u05d0 \u05ea\u05d5\u05db\u05dc \u05dc\u05d1\u05d7\u05d5\u05e8 \u05d9\u05d5\u05ea\u05e8 \u05de\u05d0\u05e9\u05e8 {0} {1}",//no i18n
	"abm.firmographics": "Firmographics" ,// NO I18N
	"crux.custom.field.greater.than.equalto":"{0} צריך להיות גדול או שווה ל{1}.",
	"crux.users.selected.plural" : "נבחרו {0} משתמשים.",
	"crux.user.selected.singular" :"נבחר משתמש {0}.",
	"crux.criteria.empty.secondaryfield.module" : "לא נמצא שדה {0} תואם במודול {1}",
	"crux.criteria.empty.secondaryfield" : "אין שדה {0} אחר הזמין להשוואה, אנא הזן ערך להשוואה.", 
	"crux.logged.in.role.definition" : "תפקיד המשתמש שמפעיל את פעולות הרישום",
	"crux.max.limit.unselect" : "אינך יכול לבטל את הבחירה של יותר מאשר{0} {1}.", //NO I18N
	"crux.existing.tag" : "\u05f4{0}\u05f4 כבר נבחר" //NO I18N
	
}
